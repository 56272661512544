<template>
  <b-modal centered ref="modal" title="إضافة عهدة" hide-footer>
    <validation-observer ref="simpleRules">
      <b-form style="" @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group label="" label-for="v-cost">
              <validation-provider #default="{ errors }" name="المبلغ" rules="required">
                <b-form-input id="v-cost" v-model.number="cost" type="number" :state="errors.length > 0 ? false : null" placeholder=" المبلغ بالجنيه" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              :disabled="isValidating"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              إضافه
            </b-button>
            <b-button :disabled="isValidating" @click="hideModal" variant="danger" v-ripple.400="'rgba(186, 191, 199, 0.15)'" style="margin-right: 15px">
              رجوع
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormCheckboxGroup,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton
  },

  directives: {
    Ripple
  },
  props: ['id'],
  data() {
    return {
      isValidating: false,
      locale: 'ar',
      required,
      cost: null
    }
  },
  mounted() {
    if (this.id) this.$route.params.id
    // switch to arabic in validation
    localize(this.locale)
  },
  methods: {
    showModal() {
      this.resetForm()
      this.$refs.modal.show()
    },
    hideModal() {
      this.$refs.modal.hide()
    },
    resetForm() {
      this.cost = null
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        this.isValidating = true
        if (success) {
          // eslint-disable-next-line
          this.AddCost()
        }
        this.isValidating = false
      })
    },

    async AddCost() {
      const payload = { agentId: this.$route.params.id, value: this.cost, date: new Date() }
      try {
        const response = await this.$store.dispatch('AddCostToAgent', payload)
        if ((response.status = 204)) {
          this.$emit('add-cost', payload.value)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم إضافة العهده',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
          this.$router.push({
            name: 'agent-details',
            params: { id: this.$route.params.id }
          })
        }
        this.$refs.modal.hide()
      } catch (err) {
        if (err.response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errorMessages[0] ? err.response.data.errorMessages[0] : '',
              icon: 'XIcon',
              variant: 'warning'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger'
            }
          })
        }
      }
    }
  }
}
</script>
